//import '@babel/polyfill';
// import 'url-search-params-polyfill';

import { ScrollToPlugin } from 'gsap/all';
// import TimelineLite from 'gsap';

const scrollToPlugin = ScrollToPlugin; // need to include to bundle on build

// import pageLoader from './page-loader';

// import navSearch from './components/nav-search';
// import mobileSearch from './components/mobile-search';
// import headerProductsDesktop from './components/header-products-desktop';
// import headerLocationsDesktop from './components/header-locations-desktop';
// import headerWhyLandmarkDesktop from './components/header-why-landmark-desktop';
import headerNavMobile from './components/header-nav-mobile';
import dropdown from './components/dropdown';
import socialShare from './components/social-share';
import showEnquiries from './components/show-enquiries';
import styleSelect from './components/style-select';
import disableOnTouch from './components/disable-for-touch-devises';
import lazyImages from './components/lazy-images';
// import showOnScroll from './animations/show-on-scroll';
import forms from './forms';
import accordion from './components/accordion';
import stickyHeader from './components/sticky-header';

// Builder JS
import builderTeamSlider from './components/builder-team-slider';

if ('scrollRestoration' in history) {
  // Back off, browser, I got this...
  history.scrollRestoration = 'manual';

}

window.addEventListener('load', () => {
  // pageLoader(); // fire scripts for current page
  // navSearch();
  // mobileSearch();
  // headerProductsDesktop();
  // headerLocationsDesktop();
  // headerWhyLandmarkDesktop();
  headerNavMobile();
  dropdown();
  showEnquiries();
  styleSelect();
  disableOnTouch();
  lazyImages();
  // showOnScroll();
  socialShare();
  forms();
  accordion();
  stickyHeader();
  builderTeamSlider();
});
