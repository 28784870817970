/**
 * This function is required for the new header form popup rework.
 */
export default () => {
    const enquiriesButtonElement = $('.show-enquiries'),
        buildingFormElement = $('#building-desktop-popup');

    // Early exit, if class/id are not present on page exit function.
    if (
        enquiriesButtonElement.length === 0 ||
        buildingFormElement.length === 0
    ) {
        return;
    }

    // Hide/Show form when header is clicked.
    enquiriesButtonElement.on('click', function() {
        if (buildingFormElement.is(':visible')) {
            buildingFormElement.hide();
        } else {
            buildingFormElement.show();
        }
    });
}