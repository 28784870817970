export default() => {
    const teamSlider = $('.team-slider');
    const teamSelectorSlider = $('.team-selector-slider');

    if (teamSlider) {
        // Main Slider
        teamSlider.slick({
            slidesToShow: 1,
            prevArrow:"<button type='button' class='slick-prev testimonials-carousel-arrow-left'></button>",
            nextArrow:"<button type='button' class='slick-next testimonials-carousel-arrow-right'></button>",
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            asNavFor: '.team-selector-slider',
        });
    }

    if (teamSelectorSlider) {
        // Mini slider selector
        teamSelectorSlider.slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            asNavFor: '.team-slider',
            focusOnSelect: true,
            swipeToSlide: true,
        });
    }
}